import "babel-polyfill";
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import './js/favicons';
import runtime from 'serviceworker-webpack-plugin/lib/runtime';

import './scss/app.scss';
import './css/style.css';
import './css/typography.css';

//Registra o serviceworker
/*if ('serviceWorker' in navigator && (window.location.protocol === 'https:' || window.location.hostname === 'localhost')){
  const registration = runtime.register();
}*/