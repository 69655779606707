
import '../favicons/android-chrome-192x192.png';
import '../favicons/android-chrome-512x512.png';
import '../favicons/apple-touch-icon.png';
import '../favicons/browserconfig.xml';
import '../favicons/favicon-16x16.png';
import '../favicons/favicon-32x32.png';
import '../favicons/favicon.ico';
import '../favicons/mstile-70x70.png';
import '../favicons/mstile-144x144.png';
import '../favicons/mstile-150x150.png';
import '../favicons/mstile-310x150.png';
import '../favicons/mstile-310x310.png';
import '../favicons/safari-pinned-tab.svg';
import '../favicons/site.webmanifest';